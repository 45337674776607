<template>
  <div>
    <h1>Welcome to Nisharga</h1>
    <b-row class="my-1 mx-0">
      <b-col lg="6" offset-lg="3" md="10" offset-md="1">
        <SignIn />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Login",

  metaInfo: {
    title: "Nisharga",
    titleTemplate: "%s | Login | Your way to the world of Nirvana",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Login and explore the world of nirvana. Get unlimited content of free Stories | Articles | Blogs | Poems and much more.",
      },
      {
        name: "og:description",
        content:
          "Login and explore the world of nirvana. Get unlimited content of free Stories | Articles | Blogs | Poems and much more.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  components: {
    SignIn: () => import(/* webpackPrefetch: true */ "./modals/SignIn.vue"),
  },

  data() {
    return {};
  },
};
</script>
